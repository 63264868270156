<template>
    <LightBox
        v-if="lightbox"
        :visible="showLightbox"
        :images="slides"
        :currentImage="currentImage"
        @close="toggleLightbox($event)"
        v-bind="$attrs"
    />

    <NCarousel
        class="col-start-1 col-end-2 row-start-1 row-end-2 relative fade-in"
        :show-dots="dots"
        :show-arrow="slides.length > 1"
        :autoplay="CONFIG_OPTIONS.hero.slider.autoplay.enabled"
        :interval="CONFIG_OPTIONS.hero.slider.autoplay.interval"
        :current-index="currentSlide"
        :space-between="20"
        :slides-per-view="slidesPerView"
        :draggable="slides.length > 1"
        @update-current-index="currentSlide = $event"
    >
        <n-carousel-item
            class="my-auto relative"
            :class="[{ '!w-auto max-w-full': slidesPerView === 0 /* auto */ }]"
            v-for="(media, index) in slides"
            :key="index"
            @click="toggleLightbox(media)"
        >
            <img
                :style="{
                    'object-position': focalPoint(media),
                }"
                class="aspect-video object-center block max-h-[80vh]"
                :class="[
                    { 'rounded-2xl': CONFIG_OPTIONS.global.roundedCorners },
                    { 'cursor-pointer': lightbox && data?.length },
                    slidesPerView === 0 /* auto */
                        ? 'objecet-contain'
                        : 'object-cover w-full mx-auto',
                ]"
                :src="media.url"
                :alt="media.alt"
                v-if="media.kind == 'image'"
            />
            <video v-else-if="media.kind == 'video'" autoplay muted loop>
                <source :src="media.url" />
            </video>
            <div class="flex justify-end pl-0.5">
                <div v-if="media['license']" class="mt-1 text-small">
                    © {{ media["license"] }}
                </div>
            </div>
        </n-carousel-item>
        <template v-if="dots" #dots="{ total, currentIndex, to }">
            <div class="n-carousel__dots n-carousel__dots--dot">
                <div
                    v-for="index of total"
                    :aria-pressed="currentIndex === index - 1"
                    :aria-label="t('goToPicture', { index })"
                    role="button"
                    tabindex="0"
                    class="n-carousel__dot"
                    :class="{
                        ['n-carousel__dot--active']: currentIndex === index - 1,
                    }"
                    @click="toSlide(index - 1)"
                ></div>
            </div>
        </template>
        <template #arrow="{ prev, next }">
            <div class="custom-arrow">
                <button
                    :aria-label="t('goToPreviousPicture')"
                    type="button"
                    class="btn-round-primary-dark custom-arrow--left"
                    @click="prevSlide"
                >
                    <i
                        :class="[
                            CONFIG_OPTIONS.global.iconStyle,
                            CONFIG_OPTIONS.global.leftIcon,
                        ]"
                    ></i>
                </button>
                <button
                    :aria-label="t('goToNextPicture')"
                    type="button"
                    class="btn-round-primary-dark custom-arrow--right"
                    @click="nextSlide"
                >
                    <i
                        :class="[
                            CONFIG_OPTIONS.global.iconStyle,
                            CONFIG_OPTIONS.global.rightIcon,
                        ]"
                    ></i>
                </button>
            </div>
        </template>
    </NCarousel>
</template>

<script lang="ts" setup>
import { NCarousel, NCarouselItem } from "naive-ui";
import { CONFIG_OPTIONS, t } from "@src/globals";
import { AssetInterface } from "@gql-types/types.generated";
import { PropType, ref } from "vue";
import LightBox from "./common/LightBox.vue";
import { focalPoint } from "../helpers/style.helper.ts";

const props = defineProps({
    data: {
        type: Object as PropType<AssetInterface[]>,
        default: [],
        required: true,
    },
    dots: {
        type: Boolean,
        default: true,
    },
    lightbox: {
        type: Boolean,
        default: true,
    },
    slidesPerView: {
        type: Number,
        default: 1,
    },
});

const showLightbox = ref(false);
const slides: AssetInterface[] = props.data.map((s) => s); // to dereference props.data (used to check if original data is contains images)
const currentSlide = ref(0);
const currentImage = ref<AssetInterface>({} as AssetInterface);

// If no media is present, add a placeholder image
if (slides.length === 0) {
    slides.push({
        url: `/images/${CONFIG_OPTIONS.global.placeholderFilename}`,
        kind: "image",
        alt: t("placeholderImage"),
    } as any);
}

/** @param image If opening lightbox: image to render.
 *  @param image If closing lightbox: index of last image that was rendered (-> possibly focus/scroll to that image in parent)
 */
function toggleLightbox(image: AssetInterface | number = 0) {
    if (props.data.length === 0) return; // don't toggle lightbox if only placeholder image is present
    showLightbox.value = !showLightbox.value;
    if (typeof image === "number") {
        currentSlide.value = image;
    } else {
        currentImage.value = image;
    }
}

function nextSlide() {
    currentSlide.value = (currentSlide.value + 1) % slides.length;
}

function prevSlide() {
    currentSlide.value =
        (currentSlide.value - 1 + slides.length) % slides.length;
}

function toSlide(index: number) {
    currentSlide.value = index;
}
</script>

<style lang="scss" scoped>
.n-carousel
    .n-carousel__dots.n-carousel__dots--dot
    .n-carousel__dot.n-carousel__dot--active {
    background-color: black;
}

.n-carousel.n-carousel--bottom .n-carousel__dots {
    left: 0;
}

.n-carousel .n-carousel__dots.n-carousel__dots--dot .n-carousel__dot {
    background-color: #8d8d8d;
}

.n-carousel__dot:first-child {
    margin-left: 0;
}

.custom-arrow {
    @apply flex gap-x-4 h-max flex-wrap w-max ml-auto mt-12 s:mt-8;

    font-size: 1.25rem;
}

@media (prefers-reduced-motion: no-preference) {
    .custom-arrow button:hover {
        @apply shadow;
    }
}
</style>
